<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :center="cet"
      :before-close="handleClose"
    >
      <el-form
        v-if="id == 1"
        :model="form"
        ref="formRef"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="userId">
          <el-input v-model="form.userId" placeholder="请输入用户ID"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">创 建</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 2"
        :model="form"
        ref="formRef"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="userId">
          <el-input
            v-model="form.name"
            placeholder="输入渠道名称，只能输入字母和数字"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">生 成</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 3"
        :model="form"
        ref="formRef"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="userId">
          <el-input v-model="form.userId" placeholder="请输入用户ID"></el-input>
        </el-form-item>
        <el-form-item prop="userId">
          <el-select v-model="form.ticketId" placeholder="票种">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in form.ticketList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">创 建</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="id == 4"
        :model="form"
        ref="formRef"
        label-width="0px"
        :inline="false"
        size="normal"
      >
        <el-form-item prop="remark">
          <el-input type="textarea" v-model="form.remark" placeholder="拒绝原因，50字以内" :max="50"></el-input>
        </el-form-item>
        <el-form-item v-if="!edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onSubmit">保 存</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="edit">
          <div style="text-align:center;">
            <el-button type="primary" @click="onEdit">修 改</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '提示'
    },
    cet: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: '1'
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        userId: ''
      },
      edit: false
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    async onSubmit() {
      if (this.id == 1) {
        var { data: res } = await this.$http.post(
          '/admin/Cooperation/addWhiteList',
          this.form
        )
      } else if (this.id == 2) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/channelAdd',
          this.form
        )
      } else if (this.id == 3) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/ApplyAdd',
          this.form
        )
      } else if(this.id==4){
        var {data:res} = await this.$http.post(
          '/admin/Activity/applyCheck',
          this.form
        )
      }

      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.dialogVisible = false
        this.$emit('getlist')
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.message)
      }
    },
    async onEdit() {
      if (this.id == 2) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/channelEdit',
          this.form
        )
      }
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.dialogVisible = false
        this.$emit('getlist')
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
